import React, { useState } from 'react';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { ModalTitleText } from '../../StyledComponents/Typography';
import {
  ButtonRules,
  DropDown,
  StyledButton,
  useTheme,
} from '@canopysecurity/component-library';
import Modal from 'react-modal';
import { Tag, useAssociateTagWithDeviceMutation } from '../../gql/generated';
import { ComponentTheme } from '@canopysecurity/component-library/themes/defaultTheme';
import styled from 'styled-components';
import { StatusMessage } from '../../Components/StatusMessage';

const createStyle = (theme: ComponentTheme) => {
  return {
    button: {
      flex: 1,
      height: 40,
      marginLeft: '8px',
      marginRight: '8px',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.background.surface,
      borderRadius: theme.radii.radiusLarge,
    },
    footerBackgroundContainer: {
      width: '100%',
      paddingTop: '20px',
      paddingHorizontal: '9px',
      backgroundColor: theme.background.surface,
    },
    bodyContent: {
      display: 'flex',
      marginTop: '27px',
      marginBottom: '27px',
      paddingLeft: '17px',
      paddingRight: '17px',
      justifyContent: 'space-between',
    },
  } as const;
};

const FormInput = styled.input`
  height: ${pixelRatio.heightPercentageToDP('3.2')}px;
  width: 100%;
`;

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

const DropdownContainer = styled.div``;

export const AssociateTagModal = ({
  tags,
  isVisible,
  onClose,
  refetch,
}: {
  tags: Tag[];
  isVisible: boolean;
  onClose: () => void | Promise<void>;
  refetch: () => void;
}) => {
  const theme = useTheme();
  const styles = createStyle(theme);

  const [selectedTag, setSelectedTag] = useState<string>();
  const [serialNumber, setSerialNumber] = useState<string>();

  const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGreyRegularE};
  `;

  const [associateTagWithDevice, { loading, error }] =
    useAssociateTagWithDeviceMutation();

  const handleAssociateTag = async () => {
    try {
      const tagId = tags?.find((t) => t.tagName === selectedTag)?.tagId;

      if (!serialNumber) {
        throw new Error('Must have a serialNumber');
      }
      if (!tagId) {
        throw new Error('Unable to find tag');
      }

      await associateTagWithDevice({
        variables: {
          input: {
            tagId,
            serialNumber,
          },
        },
      });
      refetch();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      style={{
        content: {
          paddingTop: 30,
          paddingBottom: 30,
          paddingLeft: 20,
          paddingRight: 20,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          minWidth: '26vw',
          position: undefined,
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999,
        },
      }}
      ariaHideApp={false}
    >
      <ModalTitleText>Associate a Tag</ModalTitleText>
      <Divider />
      <FormLabelContainer>
        <FormInput
          placeholder="Enter a serial number"
          maxLength={120}
          value={serialNumber}
          autoCapitalize="none"
          onChange={(event): void => setSerialNumber(event.target.value)}
        />
      </FormLabelContainer>
      <DropdownContainer>
        <DropDown
          items={tags.map((t) => t.tagName).filter((t): t is string => !!t)}
          onValueChange={(value: string) => setSelectedTag(value)}
          placeholderText="Select a tag to associate"
          dropdownListContainerStyle={{
            width: pixelRatio.widthPercentageToDP('10'),
            zIndex: 99,
          }}
          selectedValue={selectedTag}
        />
      </DropdownContainer>
      <Divider />
      {error && (
        <>
          <StatusMessage text={error.message} isError /> <Divider />
        </>
      )}

      <div style={styles.footerBackgroundContainer}>
        <div style={styles.buttonContainer}>
          <StyledButton
            disabled={loading}
            onPress={onClose}
            buttonRules={ButtonRules.secondary}
            style={styles.button}
          >
            Cancel
          </StyledButton>
          <StyledButton
            disabled={loading || !serialNumber || !selectedTag}
            onPress={handleAssociateTag}
            buttonRules={ButtonRules.primaryA}
            style={styles.button}
          >
            {loading ? 'Associating Tag...' : 'Associate Tag'}
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};
